import React, { useEffect, useState } from "react";
import {
  //  sendSyncCommandRequest,
  sendAsyncCommandRequest,
  CmdResponse,
  CMD_START_ANSWER,
  CMD_ANSWER,
  CMD_FLAG,
  //  CMD_BANK,
  CMD_PAIR_REQUEST,
  CMD_PAIR_RESPONSE,
  BIT_CLIENT_NETWORK_ERROR,
  //  BAD_RESPONSE,
  BIT_OK,
  //  CmdRequest,
} from "./fetcher";
import { toLatin } from "./utils.ts";
import "./Quiz.css";
//import Header from "./Header";

const Quiz: React.FC = () => {
  const responseInit: CmdResponse = {
    errCode: BIT_CLIENT_NETWORK_ERROR,
    token: null,
    payload: {
      question: "loading",
      answers: ["loading", "loading", "loading", "loading"],
      indexLastAnswer: 0,
      idQuestion: 0,
      inBank: 0,
      percent: 0,
      attempts: 0,
    },
  };

  type ArrayCmdTResponses = Array<CmdResponse>;
  type ArrayAnswers = Array<number>;
  type BankPayload = {
    inBank: number;
    percent: number;
    attempts: number;
  };

  // State management
  const [selectedOptions, setSelectedOptions] = useState<ArrayAnswers>([0]);
  const [loading, setLoading] = useState<boolean>(true);
  const [ixHistory, setIxHistory] = useState<number>(0);
  const [retryCount, setRetryCount] = useState(1);
  const [isLatin, setLatin] = useState<boolean>(false);
  const [isPairingHere, requestPairHere] = useState<boolean>(false);
  const [isPairingElsewhere, requestPairElsewhere] = useState<boolean>(false);
  const [answers, setAnswers] = useState<ArrayAnswers>([0]);
  const [historyResponse, setHistoryResponse] = useState<ArrayCmdTResponses>([
    responseInit,
  ]);
  const [score, setScore] = useState<BankPayload>({
    inBank: 0,
    percent: 0,
    attempts: 0,
  });
  const [pairCode, setPairCode] = useState<string | null>(null);
  //  const [numDisplayChars, setNumDisplayChars] = useState<number>(0);

  const AUDIO_LOCATION = "../audio/";
  const correctAnswerAudio = new Audio(AUDIO_LOCATION + "correct_answer.mp3");
  const incorrectAnswerAudio = new Audio(
    AUDIO_LOCATION + "incorrect_answer.mp3"
  );

  let word: HTMLAudioElement;
  //  let history = new Array<CmdResponse>();

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  // Toggle menu visibility
  const toggleMenu = (): void => {
    setIsMenuVisible((prev) => !prev);
  };

  // Close the menu when clicking outside (optional)
  const handleClickOutside = (event: MouseEvent): void => {
    const target = event.target as HTMLElement;
    if (!target.closest(".menu-container") && !target.closest(".menu-icon")) {
      setIsMenuVisible(false);
    }
  };

  function startAnswer() {
    sendAsyncCommandRequest(
      {
        cmdCode: CMD_START_ANSWER,
      },
      (data: CmdResponse) => {
        if (data.errCode === BIT_OK) {
          //          setAnswers((prevAns) => [...prevAns, data.payload!.indexLastAnswer]);
          let d = new Date();
          data.timestamp = d.getTime();
          setHistoryResponse((prevItems) => [...prevItems, data]);
          setIxHistory(1);
          setLoading(false);
          setScore({
            inBank: data!.payload!.inBank,
            percent: data!.payload!.percent,
            attempts: data!.payload!.attempts,
          });
          matrixStyle(0);

          setTimeout(() => {
            word = new Audio(
              AUDIO_LOCATION + pad(data!.payload!.idQuestion, 6) + ".mp3"
            );
          }, 10);
        } else {
          setTimeout(
            () => setRetryCount((count) => count + 1),
            Math.floor(Math.random() * Math.min(retryCount, 10)) * 1000
          ); // Retry after 1 second
        }
      }
    );
  }

  useEffect(() => {
    if (localStorage.getItem("alphabet") === "latin") {
      setLatin(true);
    }
    document.addEventListener("click", handleClickOutside);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pValue = url.searchParams.get("p");
    if (pValue) {
      if (pValue != "XXXX") {
        setPairCode(pValue);
      }
      requestPairElsewhere(true);
    }
    startAnswer();

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [retryCount]);

  function pad(num: number, size: number): string {
    let sNum = num.toString();
    while (sNum.length < size) sNum = "0" + sNum;
    return sNum;
  }

  function handleGoodResponse(data: CmdResponse, option: number) {
    setAnswers((prevAns) => [...prevAns, data.payload!.indexLastAnswer]);
    if (data.payload!.indexLastAnswer == option) {
      correctAnswerAudio.play();
    } else {
      incorrectAnswerAudio.play();
    }
    setLoading(false);
    word = new Audio(
      AUDIO_LOCATION + pad(data!.payload!.idQuestion, 6) + ".mp3"
    );
    setScore({
      inBank: data!.payload!.inBank,
      percent: data!.payload!.percent,
      attempts: data!.payload!.attempts,
    });
    setTimeout(() => {
      //              setSelectedOption(0);
      //              setLastAnswer(0);
      let d = new Date();
      data.timestamp = d.getTime();
      setHistoryResponse((prevItems) => [...prevItems, data]);
      setIxHistory((ixHistory) => ixHistory + 1);
      //setIxHistory(1);
      word.play();
      matrixStyle(0);
    }, 1000);
  }

  // Handle selecting an option
  const handleOptionClick = (option: number) => {
    console.log("handleOptionClick option = " + option);
    setSelectedOptions((prevOptions) => [...prevOptions, option]);
    setLoading(true);
    let d = new Date();
    let delay;
    let nextQuestion = historyResponse[ixHistory];
    if (nextQuestion.timestamp) {
      delay = Math.max(10, 1000 - (d.getTime() - nextQuestion.timestamp));
    } else {
      delay = 1000;
    }

    //    setNextQuestion(responseInit);
    setTimeout(() => {
      sendAsyncCommandRequest(
        {
          cmdCode: CMD_ANSWER,
          token: nextQuestion.token!,
          payload: { answer: option },
        },
        (data: CmdResponse) => {
          if (data.errCode === BIT_OK) {
            handleGoodResponse(data, option);
          } else {
            // Give it one more attempt...
            setTimeout(() => {
              sendAsyncCommandRequest(
                {
                  cmdCode: CMD_ANSWER,
                  token: nextQuestion.token!,
                  payload: { answer: option },
                },
                (data: CmdResponse) => {
                  if (data.errCode === BIT_OK) {
                    handleGoodResponse(data, option);
                  } else {
                    // Restart the whole thing
                    //            handleOptionClick(option);
                    window.location.href = "https://www.serbianschool.com";
                    window.location.reload();
                    console.log("HandleOption error " + data.errCode);
                  }
                }
              );
            }, 350);
          }
        }
      );
    }, delay);
  };

  function matrixStyle(ix: number) {
    if (ix > 10000) {
      console.log(ix);
    }
    /*
    if (ix == -1) {
      // in case of failure...
      setTimeout(() => {
        setNumDisplayChars(
          historyResponse[ixHistory]!.payload!.question!.length + 100
        );
      }, 2000);
    }
    setNumDisplayChars(ix);
    if (
      ix <= Array.from(historyResponse[ixHistory]!.payload!.question!).length
    ) {
      setTimeout(() => {
        matrixStyle(ix + 1);
      }, 100);
    }
      */
  }

  const transcribe = (s: string) => {
    if (isLatin) {
      return toLatin(s);
    } else {
      return s;
    }
  };

  const setAlphabet = (switchToLatin: boolean) => {
    setLatin(switchToLatin);
    setIsMenuVisible(false);
    if (switchToLatin) {
      localStorage.setItem("alphabet", "latin");
    } else {
      localStorage.setItem("alphabet", "cyrillic");
    }
  };

  const flagQu = () => {
    let nextQuestion = historyResponse[ixHistory];
    setIsMenuVisible(false);
    sendAsyncCommandRequest(
      {
        cmdCode: CMD_FLAG,
        token: nextQuestion.token!,
      },
      (data: CmdResponse) => {
        console.log("Flag return " + data.errCode);
      }
    );
  };

  const pairRequest = () => {
    let nextQuestion = historyResponse[ixHistory];
    console.log("pairRequest 1 token " + nextQuestion.token!);
    sendAsyncCommandRequest(
      {
        cmdCode: CMD_PAIR_REQUEST,
        token: nextQuestion.token!,
      },
      (data: CmdResponse) => {
        if (data.errCode == BIT_OK) {
          console.log("pairRequest OK");
          setPairCode(data!.payload!.question);
          requestPairHere(true);
        } else {
          console.log("pairRequest fails " + data.errCode);
          requestPairHere(false);
        }
      }
    );
  };

  /*
  const bank = () => {
    let nextQuestion = historyResponse[ixHistory];
    sendAsyncCommandRequest(
      {
        cmdCode: CMD_BANK,
        token: nextQuestion.token!,
      },
      (data: CmdResponse) => {
        if (data.errCode == BIT_OK) {
          setScore({
            inBank: data!.payload!.inBank,
            percent: data!.payload!.percent,
            attempts: data!.payload!.attempts,
          });
        }
        console.log("Bank return " + data.errCode);
      }
    );
  };
  */

  const pairWithThisUser = () => {
    requestPairElsewhere(false);
    setIsMenuVisible(false);
    pairRequest();
  };

  const pairWithAnotherUser = () => {
    requestPairHere(false);
    requestPairElsewhere(true);
    setIsMenuVisible(false);
  };

  const handlePairCodeInputChange = (value: string): void => {
    setPairCode(value);
  };

  const enterPassCode = () => {
    if (pairCode) {
      sendAsyncCommandRequest(
        {
          cmdCode: CMD_PAIR_RESPONSE,
          payload: { passCode: pairCode },
        },
        (data: CmdResponse) => {
          if (data.errCode == BIT_OK) {
            setIxHistory(0);
            setHistoryResponse([responseInit]);
            startAnswer();
            requestPairElsewhere(false);
          }
          console.log("enterPassCode " + data.errCode);
        }
      );
    }
  };

  return (
    <div className="container">
      <div style={{ position: "relative" }}>
        {/* SVG Icon */}
        <svg
          onClick={toggleMenu}
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          viewBox="0 0 356 304"
          className="menu-icon"
          style={{ cursor: "pointer" }}
        >
          <line
            x1="80"
            y1="106"
            x2="80"
            y2="304"
            stroke="currentColor"
            stroke-width="40"
          />
          <line
            x1="280"
            y1="106"
            x2="280"
            y2="304"
            stroke="currentColor"
            stroke-width="40"
          />
          <line
            x1="180"
            y1="106"
            x2="180"
            y2="304"
            stroke="currentColor"
            stroke-width="40"
          />
          <line
            x1="60"
            y1="284"
            x2="296"
            y2="284"
            stroke="currentColor"
            stroke-width="40"
          />
          <line
            x1="185.41"
            y1="18.5767"
            x2="7.40982"
            y2="89.5767"
            stroke="currentColor"
            stroke-width="40"
          />
          <line
            y1="-20"
            x2="191.638"
            y2="-20"
            transform="matrix(0.928836 0.370491 0.370491 -0.928836 178 0)"
            stroke="currentColor"
            stroke-width="40"
          />
        </svg>

        {/* Menu */}
        {isMenuVisible && (
          <div className="menu-container">
            <ul style={styles.menuList}>
              <li
                onClick={() => setAlphabet(false)}
                style={isLatin ? styles.menuItem : styles.menuItemBold}
              >
                Ћирилица
              </li>
              <li
                onClick={() => setAlphabet(true)}
                style={!isLatin ? styles.menuItem : styles.menuItemBold}
              >
                Latinica
              </li>
              <li onClick={() => flagQu()} style={styles.menuItem}>
                Flag mistake
              </li>
              <li onClick={() => pairWithThisUser()} style={styles.menuItem}>
                Pair
              </li>
              <li onClick={() => pairWithAnotherUser()} style={styles.menuItem}>
                I have a pair code
              </li>
            </ul>
          </div>
        )}
        {!isPairingHere && !isPairingElsewhere && (
          <div>
            <div className="bank">{score.inBank ? `${score.inBank}¢` : ""}</div>
            <div className="score">{score.percent}%</div>
          </div>
        )}
      </div>
      {isPairingHere && pairCode && (
        <div style={{ textAlign: "center" }}>
          <br />
          <div>
            <img
              style={{ maxWidth: "280px" }}
              src={`https://www.serbianschool.com/qrcode/${pairCode}.png`}
            />{" "}
            <br />
            Go to{" "}
            <a href={`https://www.serbianschool.com/index.html?p=${pairCode}`}>
              serbianschool.com/pair
            </a>{" "}
            and enter code <b>{pairCode}</b>
          </div>
          <br />
          <div
            onClick={() => requestPairHere(false)}
            className="answerChoiceButton"
            style={{
              cursor: "pointer",
              visibility: "visible",
            }}
          >
            Cancel
          </div>
        </div>
      )}
      {isPairingElsewhere && (
        <div style={{ textAlign: "center" }}>
          <br />
          <div>Enter your pair code here:</div>
          <input
            type="number"
            value={pairCode ?? ""}
            onChange={(e) => handlePairCodeInputChange(e.target.value)}
            maxLength={4}
            pattern="[0-9]*" // Allow only digits
            inputMode="numeric" // Mobile-friendly numeric keyboard
            style={{
              fontSize: "32px",
              width: "200px",
              margin: "100px 0px",
              textAlign: "center",
            }}
          />
          <div
            onClick={() => enterPassCode()}
            className="answerChoiceButton"
            style={
              pairCode && pairCode.length == 4
                ? {
                    cursor: "pointer",
                    visibility: "visible",
                  }
                : {
                    cursor: "none",
                    visibility: "hidden",
                  }
            }
          >
            Pair
          </div>
          <div
            onClick={() => requestPairElsewhere(false)}
            className="answerChoiceButton"
            style={{
              cursor: "pointer",
              visibility: "visible",
            }}
          >
            Cancel
          </div>
        </div>
      )}
      {!isPairingHere && !isPairingElsewhere && (
        <div style={{ textAlign: "center" }}>
          <div>
            <div className="quContainer">
              <svg
                viewBox="0 0 13 22"
                width="13"
                height="22"
                fill="currentColor"
                className="prevQu"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() =>
                  setIxHistory((ixPrev) => Math.max(ixPrev - 1, 1))
                }
                style={
                  ixHistory > 1
                    ? {
                        cursor: "pointer",
                        visibility: "visible",
                      }
                    : {
                        cursor: "none",
                        visibility: "hidden",
                      }
                }
              >
                <line
                  x1="11.1609"
                  y1="0.949857"
                  x2="2.16094"
                  y2="11.9499"
                  stroke="currentColor"
                  stroke-width="3"
                />
                <line
                  y1="-1.5"
                  x2="14.2127"
                  y2="-1.5"
                  transform="matrix(0.633238 0.773957 0.773957 -0.633238 3 9)"
                  stroke="currentColor"
                  stroke-width="3"
                />
              </svg>
              <div
                className="qu"
                style={
                  historyResponse[ixHistory]!.payload!.question!.length > 20
                    ? { fontSize: "20px" }
                    : { fontSize: "24px" }
                }
              >
                {transcribe(
                  historyResponse[ixHistory]?.payload!.question
                  //                    historyResponse[ixHistory]?.payload?.question?.substring(
                  //                    0,
                  //                    numDisplayChars
                  //                  ) ?? ""
                )}{" "}
                <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    word = new Audio(
                      AUDIO_LOCATION +
                        pad(historyResponse[ixHistory].payload!.idQuestion, 6) +
                        ".mp3"
                    );
                    word.play();
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-repeat"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z" />
                </svg>
                <svg
                  viewBox="0 0 13 22"
                  width="13"
                  height="22"
                  fill="currentColor"
                  className="nextQu"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() =>
                    setIxHistory((ixPrev) =>
                      Math.min(ixPrev + 1, historyResponse.length - 1)
                    )
                  }
                  style={
                    ixHistory < historyResponse.length - 1
                      ? {
                          cursor: "pointer",
                          visibility: "visible",
                        }
                      : {
                          cursor: "none",
                          visibility: "hidden",
                        }
                  }
                >
                  <line
                    y1="-1.5"
                    x2="14.2127"
                    y2="-1.5"
                    transform="matrix(0.633238 0.773957 0.773957 -0.633238 3 0)"
                    stroke="currentColor"
                    stroke-width="3"
                  />
                  <line
                    x1="11.1609"
                    y1="9.94986"
                    x2="2.16094"
                    y2="20.9499"
                    stroke="currentColor"
                    stroke-width="3"
                  />
                </svg>
              </div>
            </div>
            <div>
              {
                historyResponse[ixHistory]?.payload?.answers?.map(
                  (option, index) => (
                    <div
                      key={index}
                      //                      style={{
                      //                        filter: `blur(${
                      //                          Array.from(
                      //                            historyResponse[ixHistory]!.payload!.question!
                      //                          ).length - numDisplayChars
                      //                        }px)`,
                      //                      }}
                      className={
                        loading &&
                        (!selectedOptions[ixHistory] ||
                          selectedOptions[ixHistory] == 0 ||
                          selectedOptions[ixHistory] == index + 1)
                          ? "answerChoiceButton answerChoiceButton--loading"
                          : selectedOptions[ixHistory] &&
                            selectedOptions[ixHistory] == index + 1 &&
                            answers[ixHistory] &&
                            answers[ixHistory] != selectedOptions[ixHistory]
                          ? "answerChoiceButton answerChoiceButton--wrong"
                          : answers[ixHistory] &&
                            answers[ixHistory] == index + 1
                          ? "answerChoiceButton answerChoiceButton--right"
                          : loading || answers[ixHistory]
                          ? "answerChoiceButton answerChoiceButton--unselectable"
                          : "answerChoiceButton answerChoiceButton--normal"
                      }
                      onClick={() => {
                        handleOptionClick(index + 1);
                      }}
                    >
                      {option ?? "Loading"}
                    </div>
                  )
                ) /* */
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quiz;

// Inline styles for simplicity
const styles: { [key: string]: React.CSSProperties } = {
  menu: {
    position: "absolute",
    top: "0",
    left: "40px",
    /* 
    border: "1px solid #ccc",
    */
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
  },
  menuList: {
    listStyleType: "none",
    margin: 0,
    padding: "10px 0",
  },
  menuItem: {
    padding: "10px 20px",
    cursor: "pointer",
  },
  menuItemBold: {
    padding: "10px 20px",
    cursor: "pointer",
    fontWeight: "bold",
  },
  score: {
    position: "absolute",
    top: "0",
    right: "100px",
  },
};
