import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Quiz from "./Quiz.tsx";
import "./index.css";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Quiz />
  </StrictMode>
);

// Check if the current hostname is missing 'www'
if (window.location.hostname === "serbianschool.com") {
  // Redirect to the www version
  window.location.href =
    window.location.protocol +
    "//www.serbianschool.com" +
    window.location.pathname +
    window.location.search;
}

// Ensure HTTPS is enforced
if (window.location.protocol !== "https:") {
  window.location.href =
    "https://" +
    window.location.hostname +
    window.location.pathname +
    window.location.search;
}
