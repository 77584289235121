export const apiUrl = "https://www.serbianschool.com/cmd2.php";

/*
const CMD_NONE = 0;
*/
export const CMD_START_ANSWER = 1;
export const CMD_ANSWER = 2;
export const CMD_FLAG = 3;
export const CMD_BANK = 4;
export const CMD_PAIR_REQUEST = 5;
export const CMD_PAIR_RESPONSE = 6;

export const BIT_OK = 0;
export const BIT_MALFORMED = 0b00000000000000000000000000000001;
export const BIT_BAD_TOKEN = 0b00000000000000000000000000001000;
export const BIT_CLIENT_NETWORK_ERROR = 0b10000000000000000000000000000000;
export const BIT_TOO_MANY_DAILY_RESTARTS = 0b00000000000000000000000000010000;
export const BIT_BAD_BACKOFF = 0b00000000000000000000000000100000;

export type RequestPayload = {
  answer?: number;
  passCode?: string;
};
export type ResponsePayload = {
  indexLastAnswer: number;
  question: string;
  idQuestion: number;
  answers: string[];
  inBank: number;
  percent: number;
  attempts: number;
};

export type CmdResponse = {
  errCode: number;
  token: null | string;
  payload: null | ResponsePayload;
  timestamp?: number;
};

export const BAD_RESPONSE: CmdResponse = {
  errCode: BIT_CLIENT_NETWORK_ERROR,
  token: null,
  payload: null,
};

export type CmdRequest = {
  cmdCode: number;
  token?: string;
  payload?: RequestPayload;
};

export type TestCmdRequest = Omit<CmdRequest, "cmdCode"> & {
  cmdCode: number | any;
};

var iFailures = 0;

export async function sendSyncCommandRequest(
  req: CmdRequest
): Promise<CmdResponse> {
  try {
    // Use fetch to make a POST request
    const response = await fetch(apiUrl, {
      method: "POST", // Specify the request method
      headers: {
        "Content-Type": "application/json", // Specify JSON content
      },
      body: JSON.stringify(req), // Convert the requestBody to JSON
    });

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    // Parse
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Fetch error: ", error);
  }
  return BAD_RESPONSE;
}

export function sendAsyncCommandRequest(
  req: CmdRequest,
  callback: (data: CmdResponse) => void,
  boStart: boolean = true
) {
  if (boStart) {
    iFailures = 0;
  }
  // No async function keyword here, making it fire-and-forget

  // Use fetch to make a POST request without awaiting the response
  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      } else {
        iFailures = 0;
      }
      return response.json();
    })
    .then((responseData) => {
      callback(responseData);
    })
    .catch((error) => {
      if (iFailures > 5) {
        callback(BAD_RESPONSE);
        throw error;
      } else {
        setTimeout(() => {
          sendAsyncCommandRequest(req, callback, false);
        }, Math.min(60, ++iFailures) * 1000);
      }

      console.error("sendAsyncCommandRequest fetch error: ", error);
    });
  // Execution won't wait for fetch to complete
}
